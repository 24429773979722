<script setup lang="ts">
import SvgIcon from '../SvgIcon.vue'
import { computed, Ref, ref, watch } from "vue";
import { Domain } from '@/interfaces/Domain';
import { Category } from '@/interfaces/Category'
import BlackButton from "@/components/Buttons/BlackButton.vue";
import { useWindowSize } from '@vueuse/core';

const props = defineProps<{
    isOpen: boolean,
    domainsValues: Array<Domain>,
    domains: Array<string>,
    categoriesValues: Array<Category>,
    categories: Array<string>
}>();

const emit = defineEmits(['close', 'submit', 'update:domains', 'update:categories'])

const checkedDomains: Ref<string[]> = ref([])
const checkedCategories: Ref<string[]> = ref([])

const { width } = useWindowSize();
const isDesktop = computed(() => width.value >= 768);

watch(checkedDomains, (newValue: string[]) => emit('update:domains', newValue));
watch(checkedCategories, (newValue: string[]) => emit('update:categories', newValue));

watch(props, (newValue) => checkedDomains.value = newValue.domains ?? [], { immediate: true, deep: true });
watch(props, (newValue) => checkedCategories.value = newValue.categories ?? [], { immediate: true, deep: true });

const submit = () => {
    emit('submit')
}
</script>

<template>
    <Transition>
        <aside
            v-if="isOpen || isDesktop"
            class="self-start md:w-[280px] shrink-0 md:h-fit md:block md:pl-4 w-70 md:sticky md:top-[130px] xl:top-[149px]
            col-span-1 fixed top-0 left-0 h-[100dvh] w-screen z-50 md:z-10"
            aria-label="Sidebar"
        >
            <form
                id="filters"
                class="h-full bg-white rounded-[10px] border border-gray-light flex flex-col
                justify-between"
                @submit.prevent="submit"
            >
                <button
                    class="md:hidden text-gray-400 w-8 h-8 bg-gray-light rounded-[8px] border border-gray-300 absolute
                right-8 flex items-center justify-center top-6"
                    type="button"
                    aria-controls="filters"
                    aria-expanded="true"
                    @click="$emit('close')"
                >
                    <span class="sr-only">Fermer la fenêtre modale des filtre</span>
                    <SvgIcon name="x" class="w-3" aria-hidden="true" />
                </button>

                <h2 class="text-2xl p-5">
                    Filtres
                </h2>

                <div class="overflow-y-auto overflow-x-visible p-5 pt-0">
                    <div class="mt-2">
                        <h3 class="text-base">
                            Domaines
                        </h3>
                        <div v-if="domainsValues">
                            <div v-for="domain in domainsValues" :key="domain.id" class="flex items-center mt-2">
                                <input
                                    :id="domain.slug" v-model="checkedDomains"
                                    :value="domain.slug" type="checkbox"
                                    class="w-4 h-4 text-primary-light bg-gray-100 border-gray-300 rounded focus:ring-primary-light
                                focus:ring-2"
                                    @change="submit"
                                >
                                <label :for="domain.slug" class="ms-2 text-sm font-medium text-gray-900 uppercase">
                                    {{ domain.title }}
                                </label>
                            </div>
                        </div>
                    </div>

                    <div class="mt-6">
                        <h3 class="text-base">
                            Categories
                        </h3>
                        <div v-if="domainsValues">
                            <div v-for="category in categoriesValues" :key="category.id" class="flex items-center mt-2">
                                <input
                                    :id="category.slug" v-model="checkedCategories"
                                    :value="category.slug" type="checkbox"
                                    class="w-4 h-4 text-primary-light bg-gray-100 border-gray-300 rounded focus:ring-primary-light
                                focus:ring-2"
                                    @change="submit"
                                >
                                <label :for="category.slug" class="ms-2 text-sm font-medium text-gray-900">
                                    {{ category.title }}
                                </label>
                            </div>
                        </div>
                    </div>
                </div>

                <BlackButton class="md:!hidden shrink-0 mt-4 !h-[44px] m-4" @click="$emit('close')">
                    Filtrer
                </BlackButton>
            </form>
        </aside>
    </Transition>
</template>

<style scoped>

</style>
