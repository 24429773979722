<script setup lang="ts">
import { ref, onMounted, onUnmounted } from 'vue';
import { PhArrowUp } from '@phosphor-icons/vue';

const isVisible = ref(false);

const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth',
    });
};

const handleScroll = () => {
    isVisible.value = window.scrollY > 200;
};

onMounted(() => {
    window.addEventListener('scroll', handleScroll);
});

onUnmounted(() => {
    window.removeEventListener('scroll', handleScroll);
});
</script>

<template>
    <transition name="fade">
        <button
            v-if="isVisible"
            class="scroll-to-top"
            aria-label="Scroll to top"
            @click="scrollToTop"
        >
            <PhArrowUp size="24" weight="bold" />
        </button>
    </transition>
</template>

<style scoped>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.3s ease;
}
.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
.fade-enter-to,
.fade-leave-from {
    opacity: 1;
}

.scroll-to-top {
    @apply text-gray-600 hover:bg-gray-100 bg-white;
    position: fixed;
    bottom: 1.5rem;
    right: 1.5rem;
    width: 3rem;
    height: 3rem;
    border: none;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 50;
    transition: 300ms;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.scroll-to-top svg {
    fill: currentColor;
    animation: bounce-up 4s infinite;
}

@keyframes bounce-up {
    0% {
        transform: translateY(0);
    }
    10% {
        transform: translateY(-5px);
    }
    20% {
        transform: translateY(0);
    }
    30% {
        transform: translateY(-5px);
    }
    40% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(0);
    }
}
</style>
