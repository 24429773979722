import { createApp } from 'vue'
import OnboardingQuestionsContainer from "./components/OnBoarding/OnboardingQuestionsContainer.vue";
import FicheMetierLayout from "./components/FicheMetier/FicheMetierLayout.vue";
import Header from './components/FicheMetier/Header/Header.vue'
import Skills from "./components/FicheMetier/Skills/Skills.vue";
import Daily from './components/FicheMetier/Daily/Daily.vue'
import NewsEvents from './components/NewsEvents.vue'
import AccessPath from './components/FicheMetier/AccessPath/AccessPath.vue'
import ContinuingEducation from './components/FicheMetier/EvolutionProspects/ContinuingEducation.vue'
import JobCard from './components/JobCard.vue'
import RelatedCard from './components/FicheMetier/Related/RelatedCard.vue'
import Catalog from './components/Catalog/Catalog.vue'
import NewsContainer from './components/News/NewsContainer.vue'
import NewsInformationsContainer from './components/News/NewsInformationsContainer.vue'
import EventContainer from './components/Event/EventsContainer.vue'
import Documentation from './components/FicheMetier/Documentation/Documentation.vue'
import Statistics from './components/FicheMetier/Statistics/Statistics.vue'
import PrimaryButton from './components/Buttons/PrimaryButton.vue'
import SecondaryButton from './components/Buttons/SecondaryButton.vue'
import Navbar from './components/Navbar.vue'
import BackButton from './components/Buttons/BackButton.vue'
import BlackButton from './components/Buttons/BlackButton.vue'
import Favoris from "./components/Favoris/Favoris.vue";
import UserTurnAnimation from './components/Home/UserTurnAnimation.vue'
import Page404 from './components/Page404.vue'
import * as Sentry from "@sentry/vue";
import BackToTop from './components/BackToTop.vue'
import FAQ from "./components/FAQ.vue";

// AOS Animation
import AOS from 'aos';
import 'aos/dist/aos.css';

AOS.init();

const app = createApp({})

Sentry.init({
    app,
    dsn: "https://f81dd49fc59c6ddd9ef8bc15c9cfb4c1@sentry.mobilethinking.ch/11",
    integrations: [
        Sentry.replayIntegration(),
    ],

    tracePropagationTargets: ["*"],

    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
});

// Fiche métier
app.component('fiche-metier-layout', FicheMetierLayout)
app.component('fiche-metier-header', Header)
app.component('fiche-metier-skills', Skills)
app.component('fiche-metier-documentation', Documentation)
app.component('fiche-metier-daily', Daily)
app.component('fiche-metier-access-path', AccessPath)
app.component('fiche-metier-continuing-education', ContinuingEducation)
app.component('fiche-metier-card', JobCard)
app.component('fiche-metier-related-card', RelatedCard)
app.component('fiche-metier-statistics', Statistics)

// Catalogue
app.component('catalog-layout', Catalog)

// Favoris
app.component('favoris', Favoris)

// News & Events
app.component('news-container', NewsContainer)
app.component('news-informations-container', NewsInformationsContainer)
app.component('events-container', EventContainer)

/* Onboarding */
app.component('onboarding-questions-container', OnboardingQuestionsContainer)

// Global usages
app.component('news-events', NewsEvents)
app.component('button-primary', PrimaryButton)
app.component('button-secondary', SecondaryButton)
app.component('button-black', BlackButton)
app.component('navbar-menu', Navbar)
app.component('back-button', BackButton)
app.component('back-to-top', BackToTop)
app.component('faq', FAQ)

// Others
app.component('user-turn-animation', UserTurnAnimation)
app.component('page-404', Page404)

//App mount
app.mount('#app')
