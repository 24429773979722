<script setup lang="ts">
import { nextTick, onMounted, ref, watch } from 'vue'
import { Question } from "@/interfaces/Question";
import Slider from "@vueform/slider";
import { PhCaretDown, PhCaretUp } from '@phosphor-icons/vue'

const props = defineProps<{
    questions: Array<Question>,
}>();

const currentIndex = ref(0);
const sliderValues = ref<any>({});
const sliderRefs = ref<any>({});
const cardHeights = ref()

watch(sliderValues.value, (newValues) => {
    for (const [key] of Object.entries(newValues)) {
        if (sliderRefs.value[key]) {
            nextTick(() => {
                // Force the slider to update
                sliderRefs.value[key].update(newValues[key]);
            });
        }
    }
});

function updateCardHeights() {
    nextTick(() => {
        cardHeights.value = props.questions.map((_, index) => {
            const cardElement = document.getElementById(`onboarding-card-${index}`);
            return cardElement ? cardElement.clientHeight : 0;
        });
    });
}

function calculateTop(index: number): string {
    let cumulativeHeight: number = 0;

    const startingOffset: number = 100;
    const offset: number = 30;

    if (index < currentIndex.value) {
        cumulativeHeight = cardHeights.value?.slice(index, currentIndex.value).reduce((acc: number, height: number) => {
            const heightWithOffset = height + offset;
            return acc + heightWithOffset;
        }, 0) || 0;

        return `-${cumulativeHeight}px`;
    } else if (index > currentIndex.value) {
        cumulativeHeight = cardHeights.value?.slice(currentIndex.value, index).reduce((acc: number, height: number) => {
            const heightWithOffset = height + offset;
            return acc + heightWithOffset;
        }, 0) || 0;

        return `${cumulativeHeight + startingOffset}px`;
    }

    return `${startingOffset}px`;
}


function calculateOpacity(index: number) {
    return index === currentIndex.value ? 1 : 0.5;
}

function goToPrevious() {
    if (currentIndex.value > 0) {
        currentIndex.value--;
    }
}

function goToNext() {
    if (currentIndex.value < props.questions.length - 1) {
        currentIndex.value++;
    }
    else {
        submit()
    }
}

const submit = () => {
    const queryString = new URLSearchParams(sliderValues.value).toString();

    window.location.href = `/guide-de-demarrage/apply?${queryString}`;
}

onMounted(() => {
    // Initialize slider values with 5
    props.questions.forEach(question => {
        sliderValues.value[question.id] = 5;
    });

    updateCardHeights()
})
</script>

<template>
    <div class="flex relative max-w-3xl w-full px-4 h-[90svh] -mb-[80px]">
        <div class="w-full relative overflow-hidden">
            <div
                v-for="(question, index) in questions" :key="index"
                :style="{
                    top: calculateTop(index),
                    opacity: calculateOpacity(index),
                }"
                class="onboarding-card-container"
            >
                <div :id="'onboarding-card-' + index" class="onboarding-card">
                    <span>Question {{ index + 1 }} sur {{ questions.length }}</span>
                    <p class="font-medium text-3xl mt-3">
                        {{ question.question_title }}
                    </p>
                    <div class="mt-6">
                        <div class="flex items-center gap-4">
                            <div class="w-full">
                                <Slider
                                    :ref="(el: any) => sliderRefs[question.id] = el"
                                    v-model="sliderValues[question.id]"
                                    :min="1" :max="10" show-tooltip="focus"
                                />
                            </div>
                            <input
                                v-model="sliderValues[question.id]"
                                type="number" aria-describedby="helper-text-explanation"
                                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg
                                focus:ring-blue-500 focus:border-blue-500 block w-[75px] p-2.5 font-medium mt-1 text-center"
                                required
                            >
                        </div>
                        <div class="flex justify-between gap-4 mr-[92px]">
                            <span>plutôt non</span>
                            <span>plutôt oui</span>
                        </div>
                        <div class="flex justify-end pt-4" @click="goToNext">
                            <button-primary type="button" class="px-6 !h-[44px]">
                                Continuer
                            </button-primary>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="w-[80px] shrink-0 relative hidden md:block">
            <div class="absolute top-1/2 transform right-0 -translate-y-1/2 flex flex-col gap-6">
                <button
                    class="w-14 h-14 bg-white border border-gray-light rounded-[8px] flex items-center justify-center"
                    @click="goToPrevious"
                >
                    <PhCaretUp :size="22" />
                </button>
                <button
                    class="w-14 h-14 bg-white border border-gray-light rounded-[8px] flex items-center justify-center"
                    @click="goToNext"
                >
                    <PhCaretDown :size="22" />
                </button>
            </div>
        </div>
    </div>
</template>

<style src="@vueform/slider/themes/default.css"></style>

<style>
.onboarding-card-container{
    @apply w-full absolute z-10 py-1 md:py-2 flex items-center;
    transition: 1s;
}
.onboarding-card {
    @apply w-full border border-gray-light rounded-[16px] p-8 bg-white;
}
</style>
