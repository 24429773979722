<script setup lang="ts">
import { OnboardingFilter } from '@/interfaces/OnboardingFilter';
import { computed } from 'vue';
import { groupBy, uniq } from 'lodash';
import { PhXCircle } from '@phosphor-icons/vue';

const props = defineProps<{
    onboarding?: Array<OnboardingFilter>;
}>();

const emit = defineEmits(['stop-onboarding-filter']);

const filteredOnboarding = computed(() => {
    return (
        props.onboarding?.filter(
            filter =>
                filter.question_type !== 'category' && filter.question_type !== 'domain'
        ) || []
    );
});

const groupedTaxonomies = computed(() => {
    const groups = groupBy(
        filteredOnboarding.value.filter(
            filter => filter.question_type === 'skills' && filter.value > 5
        ),
        'question_type'
    );

    return Object.entries(groups).map(([type, filters]) => ({
        type,
        taxonomies: uniq(filters.flatMap(filter => filter.taxonomies)),
    }));
});

const formatText = (text: string): string => {
    return text.charAt(0).toUpperCase() + text.slice(1).replace(/-/g, ' ');
};
</script>

<template>
    <div class="bg-white border border-gray-200 p-4 rounded-xl mb-4">
        <div class="flex justify-between items-start">
            <h2 class="text-2xl mb-2">
                Résultats filtrés à l'aide de l'onglet "Filtre personnalisé"
            </h2>
            <button @click="$emit('stop-onboarding-filter')">
                <PhXCircle size="20" class="text-red-400" />
            </button>
        </div>

        <p class="text-sm mb-2">
            En fonction de vos réponses dans la page filtre personalisé, nous avons sélectionné des fiches métiers correspondant
            à vos compétences et intérêts. Ci-dessous figure la liste des savoir-être et savoir-faire que vous avez indirectement
            indiqués comme importants pour vous en répondant aux questions. Certains filtres à gauche de votre écran ont été
            pré-cochés pour vous proposer des métiers encore plus adaptés à votre profil.
        </p>

        <div v-for="group in groupedTaxonomies" :key="group.type">
            <div v-if="group.type === 'skills'">
                <h3 class="text-lg mb-1">
                    Savoir-être & Savoir-faire
                </h3>

                <div class="flex flex-wrap gap-2">
                    <span
                        v-for="(taxonomy, index) in group.taxonomies"
                        :key="index"
                        class="tag-taxonomy"
                    >
                        {{ formatText(taxonomy) }}
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<style>
.tag-taxonomy {
    @apply text-primary py-1.5 px-2 rounded-lg border text-sm;
    background: #FFFAEA;
    border-color: #FAEFDD;
}
</style>
