<script setup lang="ts">
import SvgIcon from './SvgIcon.vue'
import { Domain } from '@/interfaces/Domain';
import { Category } from '@/interfaces/Category';
import { useWindowSize } from "@vueuse/core";
import { computed, onMounted, ref } from 'vue'
import axios from 'axios'
import { generateSessionCode } from '@/utils'
import { PhArrowCircleRight, PhEye } from '@phosphor-icons/vue'

const props = withDefaults(defineProps<{
    id: string;
    link: string;
    image: string;
    title: string;
    domains?: Array<Domain>;
    categories?: Array<Category>;
    hideFavButton?: boolean;
    smallVersion?: boolean;
    favorite?: boolean;
    hasPreview?: boolean;
    activePreview?: boolean;
    score?: number;
}>(), {
    hideFavButton: false,
    smallVersion: false,
    domains: undefined,
    categories: undefined,
    favorite: false,
    hasPreview: false,
    activePreview: false,
    score: 0
});

const emits = defineEmits(['updateFavorite', 'preview']);

const { width } = useWindowSize();
const isDesktop = computed(() => width.value >= 1280);
const isFavorite = ref(false);
const domainsValues = ref<Array<Domain>>([]);

const cardHandleClick = () => {
    window.location.href = props.link
}

const getDomains = () => {
    if (props.domains) {
        domainsValues.value = props.domains;
    }
    else {
        axios.get(`/api/job-cards/${props.id}/domains`)
            .then(response => {
                domainsValues.value = response.data.data;
            })
            .catch(error => console.log(error))
    }
}

const getFavoriteStatus = () => {
    let sessionId = localStorage.getItem('sessionId');

    if (!sessionId) return false;

    let items = JSON.parse(localStorage.getItem('session_liked_items') || '[]');

    isFavorite.value = items.includes(props.id.toString());
}

const toggleFavorite = () => {
    let sessionId = localStorage.getItem('sessionId');

    if (!sessionId){
        const newSessionId = generateSessionCode();
        localStorage.setItem('sessionId', newSessionId)
        sessionId = newSessionId;
    }

    axios.post('/api/toggle-favorite', {
        jobcard_id: props.id,
        session_id: sessionId
    })
        .then(response => {
            emits('updateFavorite')
            isFavorite.value = response.data.wasAdded

            // Save list of liked fiches metier in local storage
            let items = JSON.parse(localStorage.getItem('session_liked_items') || '[]');

            if (response.data.wasAdded) {
                if (!items.includes(response.data.jocardId)) {
                    items.push(response.data.jocardId);
                }
            } else {
                items = items.filter((item: string) => item !== response.data.jocardId);
            }

            localStorage.setItem('session_liked_items', JSON.stringify(items));
        })
        .catch(error => console.log(error))
}

onMounted(() => {
    getDomains()

    if (props.favorite){
        isFavorite.value = props.favorite
    }
    else {
        getFavoriteStatus()
    }
})
</script>

<template>
    <div class="jobcard" :class="{'active-shadow':activePreview}" @click="cardHandleClick">
        <button
            v-if="!hideFavButton"
            class="w-10 h-10 bg-white rounded-full absolute z-20 right-3 top-3 flex items-center justify-center
                border border-gray-light transition duration-300 like-icon"
            :class="isFavorite ? 'text-white like-icon-active' : ''"
            @click.stop @click="toggleFavorite"
        >
            <SvgIcon name="heart" class="w-4" />
        </button>

        <div
            v-if="score"
            class="w-7 h-7 bg-white rounded-full absolute z-20 left-3 top-3 flex items-center justify-center
                border border-gray-light transition duration-300 like-icon font-medium text-primary"
            :class="isFavorite ? 'text-white like-icon-active' : ''"
            @click.stop @click="toggleFavorite"
        >
            {{ score }}
        </div>

        <a :href="link">
            <img class="w-full rounded-t-[16px] object-cover" :class="smallVersion ? 'h-48 md:h-36' : 'h-48'" :src="image" alt="">
        </a>

        <div class="pt-4 px-4 bg-white rounded-tl-3xl rounded-tr-3xl -mt-4 z-20 relative">
            <a :href="link">
                <h5
                    class="font-medium tracking-tight text-gray-900 line-clamp-2 md:min-h-[60px] hover:text-gray-700 transition
                    duration-300"
                    :class="smallVersion ? 'text-md md:text-xl mb-2' : 'text-xl xl:text-2xl mb-4'"
                >
                    {{ title }}
                </h5>
            </a>

            <hr class="my-3 border-gray-light">

            <a class="flex items-center gap-4">
                <div
                    v-for="domain in domainsValues" :key="domain.id"
                    class="flex gap-1 items-center mt-2 border rounded-full px-2 py-0.5 border-gray-light"
                >
                    <div class="w-1.5 h-1.5 rounded-full" :style="'background: ' + domain.color" />
                    <div class="text-sm uppercase font-semibold">
                        {{ domain.title }}
                    </div>
                </div>
            </a>

            <div class="flex items-center justify-between mt-1">
                <a
                    :href="link"
                    class="flex fill-black font-semibold gap-1 items-center hover:text-primary-light
                    transition duration-150 text-sm py-4 pr-4"
                >
                    Voir la fiche
                    <PhArrowCircleRight :size="20" />
                </a>

                <button
                    v-if="isDesktop && hasPreview"
                    class="flex fill-black font-semibold gap-1 items-center hover:text-primary-light
                    transition duration-150 text-sm py-4 pl-4"
                    :class="{'text-primary-light':activePreview}"
                    @click.stop
                    @click="$emit('preview')"
                >
                    Aperçu
                    <PhEye :size="20" />
                </button>
            </div>
        </div>
    </div>
</template>

<style scoped>
.jobcard {
    @apply bg-white block w-full transition duration-300 relative select-none overflow-hidden cursor-pointer;
    border-radius: 24px;
    border: 1px solid #EDEDED;
}

.jobcard:hover{
    box-shadow: 0 0 20px 4px rgba(0, 0, 0, 0.02);
    transform: translateY(-5px);
}

.active-shadow{
    box-shadow: 0 0 16px 1px rgba(241, 201, 155, 0.30)!important;
}
</style>
