<script setup lang="ts">
import { ref } from 'vue';
import { Collapse } from "vue-collapsed";
import { PhCaretDown } from "@phosphor-icons/vue";
import { FAQSection } from '@/interfaces/FAQSection'

const props = defineProps<{
    sections: Array<FAQSection>
}>();

const openItems = ref<{ [key: string]: boolean }>({});

const toggle = (sectionIndex: number, faqIndex: number) => {
    const key = `${sectionIndex}-${faqIndex}`;
    openItems.value[key] = !openItems.value[key];
};
</script>

<template>
    <div class="max-w-3xl mx-auto p-4">
        <div v-for="(section, sectionIndex) in sections" :key="section.id" class="mb-12">
            <h3 class="mb-4 mt-4 font-semibold">
                {{ section.title }}
            </h3>
            <div
                v-for="(faq, faqIndex) in section.questions_answers"
                :key="faqIndex"
                class="border-b ml-4 hover:bg-gray-50 transition duration-150"
            >
                <button
                    class="flex justify-between items-start w-full text-left focus:outline-none p-4"
                    @click="toggle(sectionIndex, faqIndex)"
                >
                    <span class="text-lg font-medium">{{ faq.question }}</span>
                    <PhCaretDown
                        :class="{ 'transform rotate-180': openItems[`${sectionIndex}-${faqIndex}`] }"
                        class="w-6 h-6 transition-transform duration-200 shrink-0 ml-12"
                    />
                </button>
                <Collapse :when="openItems[`${sectionIndex}-${faqIndex}`]" class="v-collapse">
                    <div class="text-gray-700 p-4 pt-0 mt-0 wysiwyg wysiwyg-faq" v-html="faq.answer" />
                </Collapse>
            </div>
        </div>
    </div>
</template>

<style scoped>
.v-collapse {
    transition: height 150ms ease-out;
}
</style>
